import { SvgCheckCircleFilled } from '@chilipiper/icons/src/design-system'
import React from 'react'
import { typeValidator } from '@chilipiper/utils'
import invariant from 'tiny-invariant'
import { Icon } from '../../../../old/icon'
import { Box, Text } from '../../../core-components'
import { validatePositioningProps } from '../../../helpers/validatePositioningProps'
import { ThemeColors } from '../../../theme'
import { Spinner } from '../../feedback/spinner/Spinner'
import { AriaProps, OmitInternalProperties, PositioningProps } from '../../types'
import { BaseButtonBox, BaseButtonVariant, getCommonProps } from '../base-button/BaseButton'
import { useAnchorProps, useButtonProps } from '../hooks'
import { AnchorOnlyProps, ButtonOnlyProps } from '../types'
import { EndContent, EndContentProvider, StartContent, StartContentProvider } from '../../data'

type CommonProps = PositioningProps & {
  INTERNAL_isFocusVisible?: boolean
  INTERNAL_nativeOnClick?: (event: React.MouseEvent) => void
  UNSAFE_hexColor?: string
  ariaProps?: AriaProps
  children: string | string[]
  'data-id'?: string
  'data-test-id'?: string
  endContent?: React.ReactNode
  isDisabled?: boolean
  isFullWidth?: boolean
  isLoading?: boolean
  isSuccess?: boolean
  startContent?: React.ReactNode
  variant: BaseButtonVariant
  size?: 7 | 8
}

export type ButtonProps = CommonProps & ButtonOnlyProps

export type AnchorProps = CommonProps & AnchorOnlyProps

type Props = ButtonProps | AnchorProps

export const ButtonInternal = ({ children, ...props }: Props) => {
  if (props.as === 'a') {
    return <ButtonAsAnchor {...props}>{children}</ButtonAsAnchor>
  } else {
    return <ButtonAsButton {...props}>{children}</ButtonAsButton>
  }
}

type PublicProps = OmitInternalProperties<ButtonProps> | OmitInternalProperties<AnchorProps>

export const ButtonPublic = (props: PublicProps) => <ButtonInternal {...props} />

const ButtonStartContent = (props: React.ComponentPropsWithoutRef<typeof StartContent>) => (
  <StartContent {...props} />
)
const ButtonEndContent = (props: React.ComponentPropsWithoutRef<typeof EndContent>) => (
  <EndContent {...props} />
)

ButtonPublic.StartContent = ButtonStartContent
ButtonPublic.EndContent = ButtonEndContent

const ButtonAsButton = ({
  isDisabled,
  isLoading,
  onClick,
  type,
  INTERNAL_isFocusVisible,
  INTERNAL_nativeOnClick,
  variant,
  children,
  isFullWidth,
  startContent,
  endContent,
  isSuccess,
  'data-id': dataId,
  'data-test-id': dataTestId,
  as: _as, // eslint-disable-line @typescript-eslint/no-unused-vars
  ariaProps,
  UNSAFE_hexColor,
  size,
  ...positioningProps
}: ButtonProps) => {
  validatePositioningProps(positioningProps, 'Button')

  const { ref, isFocusVisible, buttonProps } = useButtonProps({
    onClick,
    type,
    INTERNAL_isFocusVisible,
    INTERNAL_nativeOnClick,
    isDisabled: isDisabled || isLoading || isSuccess,
    ...ariaProps,
  })
  const { color, ...commonProps } = getCommonProps({ variant, isFocusVisible, isFullWidth, size })
  invariant(
    startContent === undefined || typeValidator(ButtonStartContent)(startContent),
    `Button: startContent must be a Button.StartContent component`
  )
  invariant(
    endContent === undefined || typeValidator(ButtonEndContent)(endContent),
    'Button: endContent must be a Button.EndContent component'
  )

  return (
    <BaseButtonBox
      as='button'
      ref={ref}
      data-id={dataId}
      data-test-id={dataTestId}
      {...commonProps}
      {...positioningProps}
      {...buttonProps}
      UNSAFE_hexColor={UNSAFE_hexColor}
    >
      <Content
        isSuccess={isSuccess}
        isLoading={isLoading}
        color={color}
        startContent={startContent}
        endContent={endContent}
      >
        {children}
      </Content>
    </BaseButtonBox>
  )
}

const ButtonAsAnchor = ({
  isDisabled,
  isLoading,
  INTERNAL_isFocusVisible,
  INTERNAL_nativeOnClick,
  variant,
  children,
  isFullWidth,
  rel,
  target,
  href,
  isSuccess,
  'data-id': dataId,
  'data-test-id': dataTestId,
  onClick,
  startContent,
  endContent,
  as: _as, // eslint-disable-line @typescript-eslint/no-unused-vars
  ariaProps,
  size,
  ...positioningProps
}: AnchorProps) => {
  validatePositioningProps(positioningProps, 'Button')

  const { anchorProps, isFocusVisible, ref } = useAnchorProps({
    ...ariaProps,
    onClick,
    INTERNAL_isFocusVisible,
    INTERNAL_nativeOnClick,
    isDisabled: isDisabled || isLoading || isSuccess,
    rel,
    target,
    href,
  })
  const { color, ...commonProps } = getCommonProps({ variant, isFocusVisible, isFullWidth, size })
  invariant(
    startContent === undefined || typeValidator(ButtonStartContent)(startContent),
    `Button: startContent must be a Button.StartContent component`
  )
  invariant(
    endContent === undefined || typeValidator(ButtonEndContent)(endContent),
    'Button: endContent must be a Button.EndContent component'
  )

  return (
    <BaseButtonBox
      as='a'
      ref={ref}
      data-id={dataId}
      data-test-id={dataTestId}
      {...commonProps}
      {...positioningProps}
      {...anchorProps}
    >
      <Content
        isSuccess={isSuccess}
        isLoading={isLoading}
        color={color}
        startContent={startContent}
        endContent={endContent}
      >
        {children}
      </Content>
    </BaseButtonBox>
  )
}

const Content = ({
  color,
  children,
  isLoading,
  endContent,
  startContent,
  isSuccess,
}: Pick<Props, 'isLoading' | 'startContent' | 'endContent' | 'children' | 'isSuccess'> & {
  color: ThemeColors
}) => {
  return (
    <>
      <StartContentInternal
        startContent={startContent}
        isSuccess={isSuccess}
        isLoading={isLoading}
      />
      <Text color={color} textStyle='button' py='1px' whiteSpace='nowrap'>
        {children}
      </Text>
      {endContent && (
        <Box ml={2} w={4} h={4}>
          <EndContentProvider value={{}}>{endContent}</EndContentProvider>
        </Box>
      )}
    </>
  )
}

const StartContentInternal = ({
  isSuccess,
  isLoading,
  startContent,
}: Pick<Props, 'isSuccess' | 'startContent' | 'isLoading'>) => {
  if (isLoading) {
    return <Spinner mr={2} />
  }
  if (isSuccess) {
    // TODO: fix color to set success icon color to always be icon/success
    return <Icon icon={SvgCheckCircleFilled} color='icon/success' mr={2} w={4} h={4} />
  }
  if (startContent) {
    return (
      <Box mr={2} w={4} h={4}>
        <StartContentProvider value={{}}>{startContent}</StartContentProvider>
      </Box>
    )
  }
  return null
}
